svg.ds-icon {
    width: 16px;
    height: 16px;
    fill: currentColor;
}

svg.ds-icon--large {
    width: 24px;
    height: 24px;
    fill: currentColor;
}

svg.ds-icon--flipped {
    transform: scale(-1, 1);
}

/* Pulled directly from FA */
svg.ds-icon--spin {
    -webkit-animation: fa-spin 2s infinite linear;
            animation: fa-spin 2s infinite linear; }

svg.ds-icon--pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
            animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }

@keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }